.home-page {
    h1 {
        color: #fff;
        font-size: 5.5rem;
        margin: 0;
        font-family: "Coolvetica";
        font-weight: 400;
    
        &::before {
            content: '<h1>';
            font-family: "La Belle Aurore";
            color: #ffd700;
            font-size: 22px;
            position: absolute;
            margin-top: -40px;
            left: 15px;
            opacity: 0.6;
        }

        &::after {
            content: '</h1>';
            font-family: "La Belle Aurore";
            color: #ffd700;
            font-size: 22px;
            position: absolute;
            margin-top: 105px;
            margin-left: 20px;
            animation: fadeIn 1s 1.7s backwards;
            opacity: 0.6;
        }

        img {
            width: 80px;
            height: auto;
            margin-left: 10px;
            opacity: 0;
            animation: rotateIn 1s linear both;
            animation-delay: 1.5s;
        }
    }

    .text-zone {
        position: absolute;
        left: 10%;
        top: 50%;
        transform: translateY(-50%);
        width: 40%;
        max-height: 90%;
      
        h2 {
            color:#8d8d8d;
            margin-top: 20px;
            font-weight: 400;
            font-size: 1.5rem;
            font-family: sans-serif;
            letter-spacing: 3px;
            animation: fadeIn 1s 1.8s backwards;
        }
     
    }

    .flat-button {
        color: #ffd700;
        font-size: 13px;
        font-weight: 400;
        letter-spacing: 4px;
        font-family: sans-serif;
        text-decoration: none;
        padding: 10px;
        border: 1px solid #ffd700;
        margin-top: 25px;
        float: left;
        animation: fadeIn 1s 1.8s backwards;
        white-space: nowrap;

        &:hover {
            background: #ffd700;
            color: #333;
        }
       
    }
    
}
@media screen and (max-width: 1219px) {
    .home-page {
        .text-zone {
            position: initial;
            width: 100%;
            transform: none;
            padding: 10px;
            box-sizing: border-box;
            transform: translateY(25%);
        }
        .flat-button {
            float:none;
            display: block;
            margin: 30px auto 0 auto;
            width: 120px;
            transform: translateX(-100%);
        }
    }
}

@media screen and (max-width: 600px) {
    .home-page {
        .text-zone {
            position: initial;
            width: 100%;
            transform: none;
            padding: 10px;
            box-sizing: border-box;
            transform: translateY(25%);
        }
        .flat-button {
            float:none;
            display: block;
            margin: 30px auto 0 auto;
            width: 120px;
            transform: none;
        }
    }
}