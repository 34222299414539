.space-box {
}
.text-zone {
  top: 25% !important;
  display: block;
  padding-bottom: 4rem !important;
}
.contact-form {
  width: 100%;
  margin-top: 20px;

  ul {
    padding: 0;
    margin: 0;

    li {
      padding: 0;
      margin: 0;
      list-style: none;
      margin-bottom: 10px;
      overflow: hidden;
      display: block;
      position: relative;
      opacity: 0;
      animation: fadeInUp 2s 2s;
      animation-fill-mode: forwards;
      clear: both;
    }
    li.half {
      width: 49%;
      margin-left: 2%;
      float: left;
      clear: none;

      &:first-child {
        margin-left: 0;
      }
    }
  }
  input[type='text'],
  input[type='email'] {
    width: 100%;
    border: 0;
    background: #115173;
    height: 50px;
    font-size: 16px;
    color: #fff;
    padding: 0 20px;
    box-sizing: border-box;
  }
  textarea {
    width: 100%;
    border: 0;
    background: #115173;
    height: 50px;
    font-size: 16px;
    color: #fff;
    padding: 20px;
    min-height: 150px;
    box-sizing: border-box;
  }

  .flat-button {
    color: #ffd700;
    background: 0 0;
    font: 11px;
    letter-spacing: 3px;
    text-decoration: none;
    padding: 8px 10px;
    border: 1px solid #ffd700;
    // float: left;
    border-radius: 4px;
    animation: fadeIn 1s 1.8s backwards;
    white-space: nowrap;
    z-index: 99;

    &:hover {
      background: #ffd700;
      color: #333;
    }
  }
}

.info-map {
  position: absolute;
  background-color: #000;
  top: 100px;
  right: 40%;
  width: 270px;
  padding: 20px;
  color: #fff;
  font-family: 'Helvetica';
  font-size: 20px;
  font-weight: 300;
  z-index: 9;
  opacity: 0;
  animation: fadeIn 1s 1.5s;
  animation-fill-mode: forwards;

  span {
    font-size: 18px;
    display: block;
    padding-top: 20px;
    color: #ffd700;
  }
}

.map {
  position: relative;
  background: rgba(8, 253, 216, 0.1);
  float: right;
  width: 50%;
  height: 100%;
  margin-right: 2rem;
  z-index: 3;
}

.leaflet-container {
  position: relative;
  width: 100%;
  height: 100%;
  opacity: 0;
  animation: backInRight 1s 1.2s;
  animation-fill-mode: forwards;
}

@media screen and (max-width: 719px) {
  .map {
    transform: translateY(15%) !important;
    // margin-top: 15% !important;
    position: relative;
  }
}

@media screen and (max-width: 1219px) {
  .contact-page .text-zone {
    transform: translateY(10%) !important;
    box-sizing: border-box;
  }
  .text-zone {
    margin-bottom: 1rem;
  }
  .map {
    float: none;
    display: block !important;
    width: 90%;
    height: 50%;
    transform: none;
    margin-bottom: 2rem;
    margin-left: 5%;
    margin-right: 5% !important;
  }
  .info-map {
    display: none;
  }

  .flat-button {
    position: relative;
    display: block;
    float: none;
  }
}
