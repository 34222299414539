$primary: #ffd700;

.portfolio-list {
  max-width: 40%;
  display: block;
  a {
    font-size: 2rem;
    color: #4d4d4e;
    display: block;
    line-height: 2rem;
    position: relative;
    text-decoration: none;
    padding: 10px 0;
  }
  ul {
    padding: 0;
    margin: 0;
    li {
      padding: 0;
      margin: 0;
      list-style: none;
      margin-bottom: 10px;
      display: block;
      position: relative;
      opacity: 0;
      animation: fadeInUp 2s 2s;
      animation-fill-mode: forwards;
      clear: both;
    }
  }
}

.portfolio-container {
  max-height: 105%;
  display: flex;
  position: absolute;
  left: 30%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  grid-gap: 2rem;
  margin-top: 0rem;
  right: 5%;
  overflow-y: scroll;
  box-sizing: border-box;
  .portfolio-item {
    width: 100%;
    display: flex;
    justify-content: center;
    flex: 1;
    position: relative;
    border-radius: 15px;
    box-sizing: border-box;
    &:first-child {
      margin-top: 1rem;
    }
    &:last-child {
      margin-bottom: 2rem;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 15px;
      z-index: 1;
    }
    .hover-item {
      display: flex;
      justify-content: center;
      word-wrap: break-word;
      text-align: center;
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      background: rgb(2, 0, 36);
      background: -moz-linear-gradient(
        144deg,
        rgba(2, 0, 36, 1) 0%,
        rgba(2, 36, 69, 1) 59%,
        rgba(0, 212, 255, 1) 96%
      );
      background: -webkit-linear-gradient(
        144deg,
        rgba(2, 0, 36, 1) 0%,
        rgba(2, 36, 69, 1) 59%,
        rgba(0, 212, 255, 1) 96%
      );
      background: linear-gradient(
        144deg,
        rgba(2, 0, 36, 1) 0%,
        rgba(2, 36, 69, 1) 59%,
        rgba(0, 212, 255, 1) 96%
      );
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#020024",endColorstr="#00d4ff",GradientType=1);
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      border-radius: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      opacity: 0;
      transform: scale(0);
      transition: all 0.7s ease-in-out;
      z-index: 3;
      font-family: sans-serif;

      h3 {
        font-size: 5rem;
        color: $primary;
        margin-bottom: 1rem;
        font-family: sans-serif;
        font-weight: bold;
      }
      p {
        font-size: 2.5rem;
        padding: 0px 5%;
        line-height: 3rem;
        font-style: italic;
      }
      ul {
        word-spacing: 0.3rem;
        text-align: left;
      }
      li {
        font-size: 1.9rem;
        color: antiquewhite;
      }
      pre {
        font-size: 2rem;
        color: antiquewhite;
        font-style: italic;
        position: relative;
        word-spacing: 0.5rem;
        p {
          color: $primary;
        }
      }
      .icons {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 2%;

        svg {
          width: 55px;
          height: 55px;
          padding: 0 20px;
          color: #000;
          cursor: pointer;
          transition: all 0.7s ease-in-out;

          &:hover {
            color: $primary;
            transform: translateY(-5px);
            opacity: 1;
          }
        }
        span {
          font-size: 62px;
          color: #000;
          cursor: pointer;
          transition: all 0.7s ease-in-out;
          &:hover {
            color: $primary;
            transform: translateY(-5px);
            opacity: 1;
          }
        }
        &.truck {
          h3 {
            font-size: 4.5rem;
          }
          p {
            font-size: 2rem;
          }
          .technologies {
            position: relative;
            top: -5%;
          }
          .icons {
            position: relative;
            top: -12%;
          }
        }
      }
    }
    &:hover .hover-item {
      opacity: 0.9;
      transform: scale(1);
    }
  }
}
@media screen and (max-width: 425px) {
  .portfolio-item {
    h3 {
      font-size: 0.5rem !important;
    }
  }
  .hover-item {
    position: initial;
    width: 100%;
    height: auto;
    box-sizing: border-box;
    font-size: 30%;
    .hover-item-content {
      position: relative;
      scale: 90%;
      top: 1%;
    }

    h3 {
      font-size: 2rem !important;
      position: relative;
    }
    p {
      font-size: 1.1rem !important;
      line-height: 1rem !important;
    }
    pre {
      position: absolute;
      font-size: 1rem !important;
      line-height: 1rem !important;
      word-spacing: 0.5rem !important;
    }
    li {
      font-size: 1rem !important;
    }
    svg {
      position: initial;
      width: 35px !important;
      height: 35px !important;
      margin-bottom: 20px;
      opacity: 1;
      color: $primary !important;
    }
    span {
      font-size: 43px !important;
      margin-bottom: 20px;
      opacity: 1;
      color: $primary !important;
    }
  }
}

@media screen and (max-width: 620px) {
  .hover-item-content {
    scale: 85% !important;
  }
}
@media screen and (max-width: 820px) {
  .hover-item-content {
    scale: 65%;
  }
}

@media screen and (max-width: 1219px) {
  .portfolio-page {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    overflow-x: hidden;

    .portfolio-list {
      max-width: 100% !important;
      ul {
        display: grid;
        grid-template-columns: auto auto auto;
        justify-content: space-around;
        row-gap: 1rem;
        column-gap: 2rem;
        li {
          text-align: center;
        }
      }
    }

    h1 {
      transform: translateY(40%);
      margin-left: -20px;
    }
    .portfolio-container {
      left: 5%;
    }
    .portfolio-item {
      height: auto;

      .hover-item {
        width: 100%;
        height: auto;
        box-sizing: border-box;
      }
      h3 {
        font-size: 3rem;
      }
      pre {
        position: absolute;
        font-size: 1.1rem;
        padding: 5px 4px !important;
      }
      svg {
        position: initial;
        width: 55px;
        height: 55px;
        margin-bottom: 20px;
        opacity: 1;
        color: $primary;
      }
      span {
        font-size: 65px;
        margin-bottom: 20px;
        opacity: 1;
        color: $primary;
      }
    }
    .icons {
      bottom: 5% !important;
    }
  }
}
