
.elipseContainer {
    z-index: -1;
    width: 608px;
    height: 608px;
    position: absolute;
    top: 50%;
    left: 70%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    margin: 0 auto;

    .elipse {
        position: absolute;
        top: 0;
        border-radius: 50%;
        border-style: solid;

        &.thin {
            width: 100%;
            height: 100%;
            border-width: 1px;
            border-color: #494949;
            opacity: .5;
        }
        &.thick {
            width: 93%;
            height: 93%;
            border-width: 10px;
            border-color: #022C43;
            transform: rotate(-45deg);
            top: 12px;
            left: 12px;
        }
        &.yellow {
            width:93%;
            height: 93%;
            border-width: 10px;
            border-color: #ffd700 transparent;
            transform: rotate(-45deg);
            top: 12px;
            left: 12px;
            animation: elipseRotate 15s ease-in-out infinite;

            &:hover {
                animation-play-state: paused;
            }
        }
       
    }

    @keyframes elipseRotate {
        0% {
            transform: rotate(-45deg);
        }
        100% {
            transform: rotate(-405deg);
        }
    }
   
    .image {
        position: absolute;
        top: 50%;
        left: 50%;
        height:90%;
        border-radius: 50%;
        box-shadow: 8px 20px 8px 8px inset;
        transform: translate(-50%, -50%);
        z-index: 1;
 
    }
   
}

@media screen and (max-width: 1219px){
    .elipseContainer {
        position:relative;
        width: 400px;
        height: 400px;
        top: -120px;
        left: 42%;
        box-sizing: border-box;
        margin: auto;
        

        .elipse {
            position: absolute;
            top: -12px;
            left: -12px;
            border-radius: 50%;
            border-style: solid;
    
            &.thin {
                width: 110%;
                height: 110%;
                border-width: 2px;
                border-color: #494949;
                opacity: .5;
                .image {
                    position: absolute;
                    top: 220px;
                    right: auto;
                    bottom: auto;
                    left: 220px;
                    margin: auto;
                    width: 350px;
                    height: 350px;
                }
            }
            &.thick {
                width: 93%;
                height: 93%;
                border-width: 10px;
                border-color: #022C43;
                transform: rotate(-45deg);
                top: 12px;
                left: 12px;
            }
            &.yellow {
                width:93%;
                height: 93%;
                border-width: 10px;
                border-color: #ffd700 transparent;
                transform: rotate(-45deg);
                top: 12px;
                left: 12px;
                animation: elipseRotate 15s ease-in-out infinite;
    
                &:hover {
                    animation-play-state: paused;
                }
            }
           
        }
    }

}


@media screen and (max-width: 600px){
    .elipseContainer {
        position:relative;
        width: 250px;
        height: 250px;
        top: 250px;
        box-sizing: border-box;
        margin: auto;
        left: 27%;

        .elipse {
            position: absolute;
            top: -2px;
            left: -2px;
            border-radius: 50%;
            border-style: solid;
    
            &.thin {
                width: 110%;
                height: 110%;
                border-width: 2px;
                border-color: #494949;
                opacity: .5;
                .image {
                    position: absolute;
                    top: 137px;
                    right: auto;
                    bottom: auto;
                    left: 140px;
                    margin: auto;
                    width: 220px;
                    height: 220px;
                }
            }
            &.thick {
                width: 93%;
                height: 93%;
                border-width: 10px;
                border-color: #022C43;
                transform: rotate(-45deg);
                top: 12px;
                left: 12px;
            }
            &.yellow {
                width:93%;
                height: 93%;
                border-width: 10px;
                border-color: #ffd700 transparent;
                transform: rotate(-45deg);
                top: 12px;
                left: 12px;
                animation: elipseRotate 15s ease-in-out infinite;
    
                &:hover {
                    animation-play-state: paused;
                }
            }
           
        }
    }
}