.page {
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgb(2, 0, 36);
  background: -moz-linear-gradient(
    144deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(2, 36, 69, 1) 59%,
    rgba(0, 212, 255, 1) 96%
  );
  background: -webkit-linear-gradient(
    144deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(2, 36, 69, 1) 59%,
    rgba(0, 212, 255, 1) 96%
  );
  background: linear-gradient(
    144deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(2, 36, 69, 1) 59%,
    rgba(0, 212, 255, 1) 96%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#020024",endColorstr="#00d4ff",GradientType=1);

  .top-tags {
    bottom: auto;
    top: 45px;
  }

  .tags {
    color: #ffd700;
    opacity: 0.6;
    position: absolute;
    bottom: 15px;
    left: 120px;
    font-size: 26px;
    font-family: 'La Belle Aurore';
  }

  .bottom-tag-html {
    margin-left: -20px;
  }

  .container {
    width: 100%;
    will-change: contents;
    height: 90%;
    min-height: 565px;
    position: absolute;
    opacity: 0;
    top: 5%;
    margin: 0 auto;
    z-index: 1;
    transform-style: preserve-3d;
    // From animate.css
    animation: fadeIn 1s forwards;
    animation-delay: 1s;
  }

  .about-page,
  .contact-page,
  .portfolio-page {
    .text-zone {
      position: absolute;
      left: 30%;
      top: 35%;
      transform: translate(-50%);
      width: 35%;
      vertical-align: middle;
      display: table-cell;
      max-height: 90%;
    }
    h1 {
      font-size: 53px;
      font-family: 'Coolvetica';
      color: #ffd700;
      font-weight: 400;
      margin-top: 0;
      position: relative;
      margin-bottom: 40px;
      left: 10px;

      &:before {
        content: '<h1>';
        font-family: 'La Belle Aurore';
        font-size: 1.7rem;
        position: absolute;
        margin-top: -10px;
        left: -10px;
        opacity: 0.6;
        line-height: 18px;
      }
      &:after {
        content: '</h1>';
        font-family: 'La Belle Aurore';
        font-size: 1.7rem;
        line-height: 18px;
        position: absolute;
        left: -30px;
        bottom: -20px;
        margin-left: 20px;
        opacity: 0.6;
      }
    }

    p {
      font-size: 18px;
      color: #fff;
      font-family: sans-serif;
      font-weight: 300;
      min-width: fit-content;
      animation: pulse 1s;
      // Add animation to every paragraph one by one
      &:nth-of-type(1) {
        animation-delay: 1.1s;
      }
      &:nth-of-type(2) {
        animation-delay: 1.2s;
      }
      &:nth-of-type(3) {
        animation-delay: 1.3s;
      }
    }
    .text-animate-hover {
      &:hover {
        color: #fff;
      }
    }
  }
}

@media screen and (max-width: 1219px) {
  .page {
    position: initial;
  }
  .container {
    position: initial;
    height: auto;
    min-height: auto;

    &.about-page,
    &.contact-page,
    &.portfolio-page {
      .text-zone {
        position: initial;
        transform: none;
        width: 100%;
        display: block;
        padding: 20px;
        box-sizing: border-box;
      }
    }
  }

  .tags,
  .bottom-tag-html {
    display: none;
  }
  .home-page h1:after {
    display: none;
  }

  .home-page h1:before {
    display: none;
  }
}
