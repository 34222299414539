.nav-bar {
    background-color: #181818;
    width: 75px;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 3;
    min-height: 500px;

    .logo {
        display:block;
        padding: 8px 0;
        box-sizing: border-box;
    }
    img {
        display:block;
        margin: 8px auto;
        width: 34px;
        height: auto;

        &.prefix-logo {
            position:relative;
            width: 160%;
            left: -20px;
            top: -10px;           
        }
    }

    nav {
        display: block;
        text-align: center;
        position: absolute;
        height: 210px;
        top: 50%;
        margin-top: -120px;
        width: 100%;

        a {
            font-size: 30px;
            color: #4d4d4e;
            display: block;
            line-height: 50px;
            position: relative;
            text-decoration: none;
            padding: 10px 0;

            i {
                transition: all 0.5s ease-out;
            }

            &:hover {
                color:#ffd700;
                svg {
                    opacity:0;
                }
                &:after {
                    opacity: 1;
                }
            }
            &:after {
                content:"";
                font-size:9px;
                letter-spacing: 2px;
                position: absolute;
                bottom: 0;
                display: block;
                width: 100%;
                text-align: center;
                opacity: 0;
                transition: all 1s ease-out;
            }
        
        }
        a.home-link {
            &:after {
                content: "HOME";
            }
        }

        a.about-link {
            &:after {
                content: "ABOUT"
            }
        }

        a.contact-link {
            &:after {
                content: "CONTACT"
            }
        }
        a.portfolio-link {
            &:after {
                content: "PORTFOLIO"
            }
        }

        a.active {
            svg {
                color: #ffd700;
            }
        }
    }

    ul {
        position: absolute;
        bottom: 20px;
        width: 100%;
        display: block;
        padding: 0;
        list-style: none;
        text-align: center;
        margin: 0;

        li {
            a {
                padding: 5px 0;
                display: block;
                font-size: 30px;
                line-height: 16px;
            
            }
            &:hover svg {
                color: #ffd700;
            }
        }
    }
   
}
.hamburger-icon,
.close-icon {
    display: none;
}


@media screen and (max-width: 1219px) {
    .nav-bar {
        background: #022445;
        position:fixed;
        height: auto;
        min-height: auto;
        width: 100%;
        .logo {
            position:relative;
            transform: translateX(-42%);
        }
        img {
        
            &.prefix-logo {
                position:relative;
                width: 15%;
                left: 0;
                top: -10px;           
            }
        }

        ul, nav {
            display: none;
        }

        nav {
           
            width: 100%;
            height: 100%;
            background: #181818;
            top:0;
            left:0;
            position: fixed;
            z-index: 3;
            margin: 0;

            a {
                display: flex;
                justify-content: center;
                align-items: center;
            }
            a:after {
                opacity: 1;
                position: initial;
                width: auto;
                margin-left: 25px;
            }
            s svg {
                opacity: 1 !important;
            }

            &.mobile-show{
                display: block;
            }
        }
        .hamburger-icon,
        .close-icon {
            display: block;
            position: absolute;
            top:15px;
            right: 15px;
        }
        
    }
    
}